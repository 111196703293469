import { Order } from '@medusajs/medusa';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { QUERY_KEYS } from '../services/queryKeys';

interface OrderResponse {
  orders: Order[];
  count: number;
  offset: number;
  limit: number;
}

const PAGE_SIZE = 24;

export const useDealerOrders = ({ page = 1 }: { page: number }) => {
  const { data: ordersResponse, isLoading: isOrdersLoading } = useQuery({
    queryKey: [QUERY_KEYS.dealerOrder.list, page],
    queryFn: () =>
      axios.get<OrderResponse>('/store/dealers/orders', {
        params: {
          offset: (page - 1) * PAGE_SIZE,
          limit: PAGE_SIZE,
        },
      }),
  });

  return {
    orders: ordersResponse?.data.orders || [],
    total: ordersResponse?.data.count || 0,
    page: page || 1,
    pageSize: PAGE_SIZE,
    isOrdersLoading,
  };
};
